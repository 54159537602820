

.slick-prev {
    width: 50px !important;
    height: 50px !important;
    left: 25px !important;
    z-index: 10;
}
.slick-next{
    right: 25px !important;
}

.card:hover{
    margin-bottom: 5px;
}

.news{
    transform: rotate(270deg);
    position: fixed;
}