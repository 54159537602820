.plus_btn{
    /* transition: 2s ease-in-out; */
    animation:cubic-bezier(0.39, 0.575, 0.565, 1)
}

/* .bike_color{
    display: flex;
    flex-wrap: wrap !important;
    align-items: center  !important;
    justify-content: space-around !important;
    width: 100%;
    height: auto;

} */