
@import url('https://fonts.googleapis.com/css2?family=Allerta&family=Assistant:wght@300&family=Inter&family=Montserrat:wght@400;500;600;700&family=Murecho:wght@300&family=Orbitron:wght@700&family=Poppins:ital,wght@1,600&family=Roboto+Mono:wght@400;500&family=Roboto:wght@400;500&family=Varela+Round&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

::-webkit-scrollbar{
  width: 10px;
}

::-webkit-scrollbar-thumb{
  background-color: #888;
}