.menu a{
    background-color: transparent;
    color: black;
    text-decoration: none;
    transition: all 100ms cubic-bezier(0.075, 0.82, 0.165, 1);
    animation: 2s;
    
}
.logo{
    border-bottom: none !important;
}

.menu a:hover{
    /* border-bottom: 2px solid rgb(79, 79, 79); */
    transform: scale(1);
    color: red;
    /* background-color: #28282853; */
}

.active{
    /* border-bottom: 2px solid rgb(79, 79, 79);   */
    color: #9F2725 !important; 
}



.navMenu{
    background-color: rgb(255, 255, 255);
}
